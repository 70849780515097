// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acquaintance-div{
    font-family: "Open Sans", sans-serif;
    background-color: #11224E; 
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;     
}

.acquaintance-section {
    color: white;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align: justify;
    font-weight: lighter;
    width: 80%;
    margin: 1 auto;
  }  
  .card-title {
  font-family: "Rubik", sans-serif;
    font-weight: 600;
    
  }

@media (max-width: 768px) {
  .card-title {
    width: 85%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/TeachingPage.css"],"names":[],"mappings":"AAGA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,UAAU;IACV,cAAc;EAChB;EACA;EACA,gCAAgC;IAC9B,gBAAgB;;EAElB;;AAEF;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');\r\n\r\n\r\n.acquaintance-div{\r\n    font-family: \"Open Sans\", sans-serif;\r\n    background-color: #11224E; \r\n    display:flex;\r\n    flex-direction:column;\r\n    justify-content:center;\r\n    align-items:center;\r\n    text-align:center;     \r\n}\r\n\r\n.acquaintance-section {\r\n    color: white;\r\n    display: flex;\r\n    justify-content:center;\r\n    align-items:center;\r\n    text-align: justify;\r\n    font-weight: lighter;\r\n    width: 80%;\r\n    margin: 1 auto;\r\n  }  \r\n  .card-title {\r\n  font-family: \"Rubik\", sans-serif;\r\n    font-weight: 600;\r\n    \r\n  }\r\n\r\n@media (max-width: 768px) {\r\n  .card-title {\r\n    width: 85%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
