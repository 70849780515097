// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;


}

.orange-box {
  width: 60%;
  height: 250px;
  background-color: #c44e00;
  text-align: center;
}

 .logo-p {
  color: white;
  font-weight: 400;
  font-size: larger;

}

.Research-headline {
  font-family: "Rubik", sans-serif;
  font-weight: 800;
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.logos {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.logos div {
  flex: 1 1;
  text-align: center;
}

.logos img {
  width: 50px;
  height: 50px; 
  filter: grayscale(100%);
}


@media (max-width: 768px) {
  .centered-box {
    flex-direction: column;
  }

  .logos {
    flex-direction: row; 
    flex-wrap: wrap; 
    justify-content: center; 
  }

  .logos div {
    width: 30%; 
    margin-right: 4%; 
    margin-bottom: 20px;
  }
  .logos div {
    width: 60%; 
    margin-right: 5%; 
    margin-left: 5%; 
    margin-bottom: 20px;
  }

  .orange-box {
    width: 71%;
    height: 120%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ResearchArea.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;;AAGrB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;CAEC;EACC,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;;AAEnB;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;;AAGA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,uBAAuB;EACzB;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,YAAY;EACd;AACF","sourcesContent":["\r\n.centered-box {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n\r\n}\r\n\r\n.orange-box {\r\n  width: 60%;\r\n  height: 250px;\r\n  background-color: #c44e00;\r\n  text-align: center;\r\n}\r\n\r\n .logo-p {\r\n  color: white;\r\n  font-weight: 400;\r\n  font-size: larger;\r\n\r\n}\r\n\r\n.Research-headline {\r\n  font-family: \"Rubik\", sans-serif;\r\n  font-weight: 800;\r\n  color: white;\r\n  font-size: 30px;\r\n  margin-bottom: 10px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.logos {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-top: 20px;\r\n}\r\n\r\n.logos div {\r\n  flex: 1;\r\n  text-align: center;\r\n}\r\n\r\n.logos img {\r\n  width: 50px;\r\n  height: 50px; \r\n  filter: grayscale(100%);\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n  .centered-box {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .logos {\r\n    flex-direction: row; \r\n    flex-wrap: wrap; \r\n    justify-content: center; \r\n  }\r\n\r\n  .logos div {\r\n    width: 30%; \r\n    margin-right: 4%; \r\n    margin-bottom: 20px;\r\n  }\r\n  .logos div {\r\n    width: 60%; \r\n    margin-right: 5%; \r\n    margin-left: 5%; \r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .orange-box {\r\n    width: 71%;\r\n    height: 120%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
