import React from "react";
import CardComponent from "./CardComponent";
import "./Card.css";
function CardListJerusalem() {
  const cardsData = [
    {
      title: `Theories of Communication: Political Psychological and
    Digital Aspects`,
      content: `This mandatory course for graduate students, led by Prof. Meital
      Balmas-Cohen, explores contemporary theoretical approaches
      in media studies, with a specific focus on political, psychological,
      and digital aspects of communication`,
    },
    {
      title: `Introduction to Mass Communication`,
      content: `This annual mandatory course for undergraduate students,
      instructed by Prof. Limor Shifman, comprehensively surveys central
      approaches in mass communication studies. It delves into theories
      regarding mass media effects and explores three major theoretical
      frameworks: functional, technological, and critical`,
    },
    {
      title: `Persuasion and Attitude Change`,
      content: `Led by Dr. Eran Amsalem, this course delves into persuasion’s
      role in shaping attitudes and behaviors across contexts like
      politics, advertising, and media. It integrates theoretical knowledge
      with practical examples, offering a comprehensive view of the art
      and science of persuasion`,
    },
    {
      title: `Political Communication`,
      content: `This mandatory course, led by Dr. Eran Amsalem, examines the
      interplay of media, politicians, and citizens in the realm of political
      communication. Key areas include the political communication
      arena, the motivations of key actors, and the effects on citizens.`,
    },
  ];

  return (
    <div className="card-container">
      {cardsData.map((card, index) => (
        <CardComponent key={index} title={card.title} content={card.content} />
      ))}
    </div>
  );
}

export default CardListJerusalem;
