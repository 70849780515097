import React from "react";
import "./ResearchArea.css";
import PoliticalCommunication from "../images/check.png";
import PoliticalPsychology from "../images/website_Omer-13.png";
import PoliticalPersuasion from "../images/website_Omer-14.png";
import PoliticalConversations from "../images/website_Omer-15.png";

function ResearchArea() {
  return (
    <div>
      <div className="centered-box">
        <div className="orange-box">
          <h2 className="Research-headline">RESEARCH AREAS</h2>
          <div className="logos">
            <div>
              <img
                src={PoliticalCommunication}
                alt="Logo 1"
                aria-label="Icon representing political communication"
              />
              <p className="logo-p">
                Political <br />
                Communication
              </p>
            </div>
            <div>
              <img
                src={PoliticalPsychology}
                alt="Logo 2"
                aria-label="Icon representing political Psychology"
              />
              <p className="logo-p">
                Political
                <br /> Psychology
              </p>
            </div>
            <div>
              <img
                src={PoliticalPersuasion}
                alt="Logo 3"
                aria-label="Icon representing political Persuasion"
              />
              <p className="logo-p">
                Political
                <br /> Persuasion
              </p>
            </div>
            <div>
              <img
                src={PoliticalConversations}
                alt="Logo 4"
                aria-label="Icon representing political Conversations"
              />
              <p className="logo-p">
                Political
                <br /> Conversations
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default ResearchArea;
