import React, { useState, useEffect } from "react";
import OmerCvPhoto from "../images/website_Omer-28.png";
import OmerCvPhotoMobile from "../images/website_Omer-38.png";

function CvPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    width: "15%",
    backgroundColor: "#c44e00",
    color: "white",
    border: "none",
    borderRadius: "0.5em",
    fontSize: "1.3em",
    cursor: "pointer",
    padding: "8px",
    marginBottom: "3%",
  };

  const pageStyle = {
    backgroundColor: "#11224E",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const imgStyle = {
    width: "100%",
  };

  const paragraphStyle = {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "justify",
    fontWeight: "lighter",
    margin: "1% auto",
    marginTop: "-5%",
  };
  const paragraphStyleMobile = {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "justify",
    fontWeight: "lighter",
    margin: "1% auto",
    marginTop: "-25%",
  };

  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "120%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    background: "#fff",
    padding: "10px",
    borderRadius: "8px",
    width: "60%",
    height: "100%",
    maxWidth: "600px",
    position: "relative",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "40px",
    color: "red",
  };

  const pdfObjectStyle = {
    width: "100%",
    height: "80%",
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = () => {
    if (isMobile) {
      const anchor = document.createElement("a");

      anchor.href = require("../images/Omer.pdf");

      anchor.target = "_blank";

      anchor.click();
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div style={pageStyle}>
      <h1 className="orange-title-cv">CV</h1>
      <img
        src={isMobile ? OmerCvPhotoMobile : OmerCvPhoto}
        alt="CV"
        style={imgStyle}
        aria-label="Portrait of the business owner, Omer"
      />
      <p style={!isMobile ? paragraphStyle : paragraphStyleMobile}>
        A copy of my CV can be found
      </p>
      <button style={buttonStyle} onClick={openModal}>
        HERE
      </button>

      {modalOpen && (
        <div style={modalOverlayStyle} onClick={closeModal}>
          <div style={modalContentStyle}>
            <span style={closeButtonStyle} onClick={closeModal}>
              &times;
            </span>
            <object
              data={require("../images/Omer.pdf")}
              type="application/pdf"
              style={pdfObjectStyle}
            >
              <p>
                It appears you don't have a PDF plugin for this browser. No
                biggie... you can{" "}
                <a href="../images/Omer.pdf" download="OmerCV.pdf">
                  click here to download the PDF file.
                </a>
              </p>
            </object>
          </div>
        </div>
      )}
    </div>
  );
}

export default CvPage;
