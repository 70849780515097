import React from "react";
import CardComponent from "./CardComponent";
import "./Card.css";
function CardListSapir() {
  const cardsData = [
    {
      title: `Language and Discourse`,
      content: `In this mandatory course, instructed by Dr. Hagar Lahav, students
      explore language and discourse. It delves into the societal use
      of language, emphasizing critical observation and refining linguistic
      skills. The course covers fundamental language theories,
      language’s role in society and culture, and practical linguistic tools.`,
    },
    {
      title: `History of the Media`,
      content: `This course, led by Prof. Naama Sheffi, surveys the evolution of
      communication media from oral traditions to the contemporary
      era of digital global communication. Each phenomenon is discussed
      by analyzing its significance and examining the relationship between
      technological inventions and social, political, and economic changes.`,
    },
  ];

  return (
    <div className="card-container">
      {cardsData.map((card, index) => (
        <CardComponent key={index} title={card.title} content={card.content} />
      ))}
    </div>
  );
}

export default CardListSapir;
