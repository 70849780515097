import React from "react";
import "./TeachingPage.css";
import CardListJer from "../components/CardListJerusalem";
import CardListSapir from "../components/CardListSapir";

function TeachingPage() {
  return (
    <div className="acquaintance-div">
      <h1 className="orange-title">TEACHING</h1>
      <p className="acquaintance-section">
        I currently serve as a teaching assistant in the Department of
        Communication and Journalism at The Hebrew University of Jerusalem and
        in the Department of Communication at Sapir College. This privileged
        role allows me to engage closely with leading communication scholars and
        contribute to a range of courses in the field of communication. My
        responsibilities include leading tutorial sessions, delivering lectures,
        providing personalized academic support to students, as well as
        constructing and grading assignments and exams. This experience has
        enriched my expertise in various communication theories and practices,
        covering historical developments and evolving trends in digital
        communication. My involvement in these courses not only deepens my own
        understanding of the field but also plays a crucial part in cultivating
        the knowledge and skills of students
      </p>
      <h4 className="card-title-big">
        Department of Communication and Journalism at The Hebrew university of
        Jerusalem
      </h4>
      <CardListJer />
      <h4 className="card-title-big">
        Department of Communication at Sapir College
      </h4>
      <CardListSapir />
      <br></br>
    </div>
  );
}

export default TeachingPage;
