// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessibility-statement {
    background-color: #f9f9f9;
    padding: 2rem;
    margin: 2rem auto;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .accessibility-statement h1 {
    color: #11224E;
    font-size: 2em;
    margin-bottom: 1rem;
  }
  
  .accessibility-statement h2 {
    color: #11224E;
    font-size: 1.5em;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .accessibility-statement p {
    color: #333;
    font-size: 1em;
    line-height: 1.6;
  }
  
  .accessibility-statement ul {
    list-style-type: disc;
    margin-left: 1.5rem;
  }
  
  .accessibility-statement li {
    margin-bottom: 0.5rem;
  }
  
  .accessibility-statement a {
    color: #c44e00;
    text-decoration: none;
  }
  
  .accessibility-statement a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AccessibilityStatement.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,cAAc;IACd,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".accessibility-statement {\r\n    background-color: #f9f9f9;\r\n    padding: 2rem;\r\n    margin: 2rem auto;\r\n    max-width: 800px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .accessibility-statement h1 {\r\n    color: #11224E;\r\n    font-size: 2em;\r\n    margin-bottom: 1rem;\r\n  }\r\n  \r\n  .accessibility-statement h2 {\r\n    color: #11224E;\r\n    font-size: 1.5em;\r\n    margin-top: 1.5rem;\r\n    margin-bottom: 1rem;\r\n  }\r\n  \r\n  .accessibility-statement p {\r\n    color: #333;\r\n    font-size: 1em;\r\n    line-height: 1.6;\r\n  }\r\n  \r\n  .accessibility-statement ul {\r\n    list-style-type: disc;\r\n    margin-left: 1.5rem;\r\n  }\r\n  \r\n  .accessibility-statement li {\r\n    margin-bottom: 0.5rem;\r\n  }\r\n  \r\n  .accessibility-statement a {\r\n    color: #c44e00;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .accessibility-statement a:hover {\r\n    text-decoration: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
