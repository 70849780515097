import React, { useState } from "react";
import "./CustomNavbar.css";
import omerLine from "../images/website_Omer-11.png";
import { Link } from "react-router-dom";

function CustomNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("HOME");

  const toggleMenu = (check) => {
    setMenuOpen(!menuOpen);
    if (check === 1) {
      document.body.style.overflow = menuOpen ? "auto" : "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    toggleMenu(0);
  };

  return (
    <div className={`navbar ${menuOpen ? "mobile-menu-open" : ""}`}>
      <div className="brand">
        <Link to="/">
          <img src={omerLine} alt="Logo" aria-label="Business logo" />
        </Link>
      </div>
      <div className={`options ${menuOpen ? "mobile-options" : ""}`}>
        <Link
          onClick={() => handleOptionClick("HOME")}
          to="/"
          className={`option ${selectedOption === "HOME" ? "orange-text" : ""}`}
        >
          HOME
        </Link>
        <Link
          onClick={() => handleOptionClick("RESEARCH")}
          to="/working-papers"
          className={`option ${
            selectedOption === "RESEARCH" ? "orange-text" : ""
          }`}
        >
          RESEARCH
        </Link>
        <Link
          onClick={() => handleOptionClick("TEACHING")}
          to="/teaching"
          className={`option ${
            selectedOption === "TEACHING" ? "orange-text" : ""
          }`}
        >
          TEACHING
        </Link>
        <Link
          onClick={() => handleOptionClick("CV")}
          to="/cv"
          className={`option ${selectedOption === "CV" ? "orange-text" : ""}`}
        >
          CV
        </Link>
      </div>
      <div className="mobile-menu-icon" onClick={() => toggleMenu(1)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
}

export default CustomNavbar;
