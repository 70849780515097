import React, { useState } from "react";
import "./ContactForm.css";
import emailjs from "@emailjs/browser";
import FaceBookImage from "../images/website_Omer-31.png";
import InstagramImage from "../images/website_Omer-32.png";
import TwitterImage from "../images/website_Omer-33.png";
import LinkedinImage from "../images/website_Omer-34.png";

function ContactForm() {
  const formRef = React.createRef();
  const [showNotification, setShowNotification] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const form = formRef.current;

    emailjs
      .sendForm("service_w602cko", "template_8gk9mnp", form, {
        publicKey: "GdJuEY6IYxLg07xNV",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setShowNotification(true);

          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="contact-form-all">
      <h2 className="blue-title">CONTACT INFORMATION</h2>
      <div className="contact-container">
        <div className="contact-info">
          <p className="about">Department of Communication & Journalism</p>
          <p className="about">Hebrew University of Jerusalem</p>
          <a href="mailto:omer.bensimhon1@mail.huji.ac.il" className="mail">
            omer.bensimhon1@mail.huji.ac.il
          </a>

          <div class="last-two-lines">
            <p className="phone">+972-52-6653432</p>
            <div className="social-logo">
              <a
                href="https://www.facebook.com/omerobs1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={FaceBookImage}
                  alt="Facebook Logo"
                  aria-label="Visit our Facebook page"
                />
              </a>
              <a
                href="https://www.instagram.com/omerobs1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramImage}
                  alt="Instagram"
                  aria-label="Visit our Instagram page"
                />
              </a>
              <a
                href="https://twitter.com/theomerobs1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TwitterImage}
                  alt="Twitter"
                  aria-label="Visit our Twitter page"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/omer-ben-simhon-b14840236/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkedinImage}
                  alt="LinkedIn"
                  aria-label="Visit our Linkedin page"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <form ref={formRef} onSubmit={sendEmail}>
            <div className="form-row">
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                value={formValues.fullName}
                onChange={handleInputChange}
                required
              />

              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone"
                value={formValues.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />

              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                value={formValues.subject}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              className="form-text"
              id="message"
              name="message"
              rows="4"
              placeholder="Leave a message"
              value={formValues.message}
              onChange={handleInputChange}
              required
            ></input>

            <button type="submit">SUBMIT</button>
          </form>
        </div>
      </div>
      <br></br>
      {showNotification && (
        <div className="notification">
          <p>Email sent successfully!</p>
        </div>
      )}
      <div className="accessibility-link">
        <a
          href="/AccessibilityStatement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility statement
        </a>
      </div>
    </div>
  );
}

export default ContactForm;
