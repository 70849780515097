import React from "react";
import "./ResearchComponent.css";

function ResearchComponent({
  title,
  authors,
  content,
  keywords,
  imageSrc,
  posterLink,
}) {
  return (
    <div className="image-text-container">
      <img
        className="image"
        src={imageSrc}
        alt="Decorative image related to text"
        aria-label="Decorative image related to text"
      />

      <div className="text-container">
        <p className="title">{title}</p>
        <p className="authors">{authors}</p>
        <p className="content">{content}</p>
        <br />
        <p className="keywords">{keywords}</p>
        {posterLink && (
          <div className="poster-link">
            <a href={posterLink} target="_blank" rel="noopener noreferrer">
              View iPoster
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResearchComponent;
