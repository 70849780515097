import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import ResearchPage from "./pages/ResearchPage";
import CustomNavbar from "./components/CustomNavbar";
import ContactForm from "./components/ContactForm";
import TeachingPage from "./pages/TeachingPage";
import CvPage from "./pages/CvPage";
import AccessibilityStatement from "./pages/AccessibilityStatement";
import "./App.css";

function App() {
  return (
    <div className="app">
      <Router>
        <CustomNavbar></CustomNavbar>
        <Routes>
          <Route path="/" element={<AboutPage />} />
          <Route path="/working-papers" element={<ResearchPage />} />
          <Route path="/teaching" element={<TeachingPage />} />
          <Route path="/cv" element={<CvPage />} />
          <Route
            path="/AccessibilityStatement"
            element={<AccessibilityStatement />}
          />
        </Routes>
        <ContactForm></ContactForm>
      </Router>
    </div>
  );
}

export default App;
