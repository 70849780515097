import React from "react";
import "./ResearchPage.css";
import ResearchComponent from "../components/ResearchComponent";
import FirstPhoto from "../images/website_Omer-22.png";
import FirstPhotoMobile from "../images/website_Omer-37.png";
import SecondPhoto from "../images/website_Omer-25.png";
import SecondPhotoMobile from "../images/website_Omer-41.png";
import LastPhoto from "../images/website_Omer-26.png";
import LastPhotoMobile from "../images/website_Omer-36.png";
import RightArrow from "../images/website_Omer-24.png";
import LeftArrow from "../images/website_Omer-23.png";
import { useState, useEffect } from "react";

function ResearchPage() {
  const [position, setPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const researchObject = [
    {
      title:
        "Winning vs. Learning: The Impact of Motivation on Attitudes in Real-Time Political Conversations",
      authors: "Omer Ben Simhon and Eran Amsalem",
      content: `
          In today's polarized landscape, political conversations between ordinary citizens often resemble battles to win rather than dialogues to understand. While this combative approach is well documented, its implications for political attitudes remain unclear because previous studies did not manipulate people's preexisting motivations in the context of real-time political conversations. To bridge this gap, we primed individuals, prior to discussing a political issue with another person holding an opposing view, to either win the debate or learn from the other side. Results show that, in line with our pre-registered expectations, a combative approach reduces the likelihood of persuasion, while a learning-oriented mindset increases it. Additionally, in contrast to a common claim in the literature, we found no effect of either motivation on attitude polarization. The results suggest that the goals people bring into political conversations shape the interactions' outcomes: a partisan mindset prevents people from reconsidering their initial attitudes, while a learning-oriented mindset can help bridge political divides.
        `,
      keywords: ` Keywords: Motivated Reasoning, Political Conversations, Political Persuasion,
        Interpersonal Persuasion, Polarization.`,
      posterLink:
        "https://apsa2024-apsa.ipostersessions.com/?s=63-CB-2E-11-6B-6B-7E-35-A4-C9-1F-54-01-2C-E0-88",
    },
    {
      title: `Encyclopedia of Political Communication: Interpersonal Communication `,
      authors: "Eran Amsalem and Omer Ben Simhon ",
      content: `
  Conversations between citizens about politics are a common form of political engagement. Political discussion, whether face-to-face or mediated, is an important source of information and can significantly influence individuals political preferences, attitudes, beliefs, and behaviors. Central to the study of interpersonal political communication is the concept of discussion heterogeneity, which examines the diversity of political views within one's political discussion network. In this entry, we define key concepts in this field of research and review some of the main findings regarding how politically heterogeneous citizens offline and online discussion networks are. We also review research exploring the effects of heterogeneous discussion on several important outcomes: vote choice, political participation, political knowledge, and attitudinal and affective polarization. We conclude by stressing the need for future research to examine the content of citizens political conversations, study additional populations, and utilize recent methodological innovations.

      `,
      keywords: ` Keywords: Interpersonal communication, Political discussion, Discussion heterogeneity, Political Behavior, Political participation, Political polarization
  `,
    },
    {
      title:
        "Willingness to Engage in Persuasive Dialogue During Conflict: Exploring Political Discussion Dynamics in the Context of the Israel-Hamas War",
      authors: "Omer Ben Simhon and Eran Amsalem",
      content: `
  This study examines the dynamics of political discussions during the active Israel-Hamas war. Using a conjoint design, the research explores how political similarities or differences influence individuals' willingness to engage in persuasive conversations. We test two conflicting expectations: whether conflict intensifies divisions, leads to homogeneous discussion networks, or fosters unity through a "rally around the flag" effect, encouraging more heterogeneous interactions. The findings reveal a dual reality: Participants were willing to engage with others holding different political positions, especially when these differences were specific to policy issues. However, broader affiliations like party or ideological alignments had minimal impact on partner selection. The study also considers the moderating roles of external threat perception and conflict orientation. Individuals with high external threat perception were more inclined to engage with differing viewpoints, while conflict-averse individuals avoided discussions with those holding different views. This research underscores the significance of individual differences and contextual factors in shaping political discussions during heightened tensions.

      `,
      keywords: ` Keywords: Persuasion, Political Discussions, War, Conflict Resolution, Polarization, Conjoint Experiment.`,
    },
  ];

  const handleRightArrowClick = () => {
    if (position == 2) {
      setPosition(0);
    } else {
      setPosition(position + 1);
    }
  };

  const handleLeftArrowClick = () => {
    if (position == 0) {
      setPosition(2);
    } else {
      setPosition(position - 1);
    }
  };

  return (
    <div className="research-page">
      <div className="arrow-container left-arrow-container">
        <img
          src={LeftArrow}
          alt="left arrow"
          aria-label="Click to move left"
          onClick={handleLeftArrowClick}
          className="arrow left-arrow"
        />
      </div>
      <h2 className="research-title">RESEARCH</h2>
      <h2 className="papers-title">WORKING PAPERS</h2>
      <ResearchComponent
        title={researchObject[position].title}
        authors={researchObject[position].authors}
        content={researchObject[position].content}
        keywords={researchObject[position].keywords}
        imageSrc={
          position === 0
            ? isMobile
              ? FirstPhotoMobile
              : FirstPhoto
            : position === 1
            ? isMobile
              ? SecondPhotoMobile
              : SecondPhoto
            : isMobile
            ? LastPhotoMobile
            : LastPhoto
        }
        posterLink={researchObject[position].posterLink}
      />
      <div className="arrow-container right-arrow-container">
        <img
          src={RightArrow}
          alt="right arrow"
          aria-label="Click to move right"
          onClick={handleRightArrowClick}
          className="arrow right-arrow"
        />
      </div>

      <div className="circle-container">
        <div
          className={`circle ${position === 0 ? "orange-circle" : ""}`}
        ></div>
        <div
          className={`circle ${position === 1 ? "orange-circle" : ""}`}
        ></div>
        <div
          className={`circle ${position === 2 ? "orange-circle" : ""}`}
        ></div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

export default ResearchPage;
