import React from "react";
import "./AccessibilityStatement.css";

function AccessibilityStatement() {
  return (
    <div className="accessibility-statement">
      <div className="statement-section" dir="rtl">
        <h1>הצהרת נגישות</h1>
        <p>
          <strong>.omerbensimhon.com</strong> מחויב לספק חוויית משתמש נגישה לכלל
          המשתמשים, כולל אנשים עם מוגבלויות. אנו פועלים לשפר את הנגישות של האתר
          שלנו בהתאם להנחיות של תקני הנגישות באינטרנט (WCAG) ותקנות חוק הנגישות.
        </p>

        <h2>צעדים שננקטו:</h2>
        <ul>
          <li>
            <strong>עיצוב נגיש:</strong> אנו משתמשים בצבעים עם ניגודיות גבוהה
            כדי להבטיח קריאות נוחה לכל המשתמשים. בנוסף, עיצבנו את האתר כך שיהיה
            נגיש ונראה טוב על מסכים שונים, כולל מסכים קטנים של מכשירים ניידים.
          </li>
          <li>
            <strong>תמיכה במקלדת:</strong> כל האלמנטים הניתנים ללחיצה באתר, כולל
            כפתורים וקישורים, ניתנים לגישה ולשימוש באמצעות מקלדת בלבד.
          </li>
          <li>
            <strong>תיאורי תוכן:</strong> אנו מספקים תיאורים טקסטיים לתמונות
            ולמדיה אחרות כדי להבטיח שמידע זה יהיה זמין למשתמשים עם קוראי מסך.
          </li>
          <li>
            <strong>נגישות ניידת:</strong> האתר מותאם למכשירים ניידים ונראה טוב
            בכל גודל מסך, עם אפשרויות ניווט נוחות למשתמשים.
          </li>
          <li>
            <strong>כותרות ותגיות:</strong> השתמשנו בכותרות ותגיות בצורה נכונה
            כדי לשפר את הניווט באתר עבור משתמשים עם קוראי מסך.
          </li>
        </ul>

        <h2>אם נתקלת בבעיה בנגישות:</h2>
        <p>
          אם אתה נתקל בקושי להשתמש באתר שלנו או אם יש לך שאלות לגבי נגישות האתר,
          נשמח לשמוע ממך. תוכל לפנות אלינו באמצעות פרטי הקשר הבאים:
        </p>
        <ul>
          <li>
            <strong>דוא"ל:</strong> pelegswisa6@gmail.com
          </li>
          <li>
            <strong>טלפון:</strong> 0542508941
          </li>
        </ul>

        <p>
          אנו מחויבים להקשיב ולבצע שיפורים על פי הצרכים של משתמשינו כדי להבטיח
          נגישות מירבית.
        </p>
        <p>
          <strong>הצהרה זו עודכנה לאחרונה ב:</strong> 07.08.2024
        </p>
      </div>

      <div className="statement-section" dir="ltr">
        <h1>Accessibility Statement</h1>
        <p>
          <strong>.omerbensimhon.com</strong> is committed to providing an
          accessible user experience for all users, including those with
          disabilities. We are working to improve the accessibility of our site
          in accordance with the Web Content Accessibility Guidelines (WCAG) and
          accessibility regulations.
        </p>

        <h2>Steps Taken:</h2>
        <ul>
          <li>
            <strong>Accessible Design:</strong> We use high-contrast colors to
            ensure readability for all users. Additionally, we have designed the
            site to be accessible and visually appealing on different screens,
            including small screens of mobile devices.
          </li>
          <li>
            <strong>Keyboard Support:</strong> All clickable elements on the
            site, including buttons and links, are accessible and usable with
            the keyboard alone.
          </li>
          <li>
            <strong>Content Descriptions:</strong> We provide text descriptions
            for images and other media to ensure this information is available
            to users with screen readers.
          </li>
          <li>
            <strong>Mobile Accessibility:</strong> The site is optimized for
            mobile devices and looks good on any screen size, with convenient
            navigation options for users.
          </li>
          <li>
            <strong>Headings and Labels:</strong> We use headings and labels
            properly to improve site navigation for users with screen readers.
          </li>
        </ul>

        <h2>If You Encounter Accessibility Issues:</h2>
        <p>
          If you experience difficulty using our site or have questions about
          the site's accessibility, we would be happy to hear from you. You can
          contact us using the following details:
        </p>
        <ul>
          <li>
            <strong>Email:</strong> pelegswisa6@gmail.com
          </li>
          <li>
            <strong>Phone:</strong> 0542508941
          </li>
        </ul>

        <p>
          We are committed to listening and making improvements based on the
          needs of our users to ensure maximum accessibility.
        </p>
        <p>
          <strong>This statement was last updated on:</strong> 07.08.2024
        </p>
      </div>
    </div>
  );
}

export default AccessibilityStatement;
