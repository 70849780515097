import React from "react";
import "./Card.css";

function CardComponent({ title, content }) {
  return (
    <div className="card">
      <h4 className="card-title">{title}</h4>
      <p className="card-content">{content}</p>
    </div>
  );
}

export default CardComponent;
