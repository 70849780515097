// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*card.css*/
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    
  }
  
  .card {
    width: 30%; 
    border: 1px solid #ccc;
    padding-inline: 3%;
    padding-bottom: 1%;
    margin: 1%;
    border-radius: 8px;
    background: #ffffff;
  }
  
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    font-weight: bolder;
    color: #c43e00  ;
    
  }
  
  .card p {
    margin: 0;
    font-weight: lighter;
  }
  .card-title-big{
    
    color: #ff5a23   ;
  }

  @media only screen and (max-width: 768px) {
    .card {
      width: 80%;
      margin: 15px; 
      padding: 10px;
      padding-bottom: 20px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Card.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,WAAW;;EAEb;;EAEA;IACE,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;;EAElB;;EAEA;IACE,SAAS;IACT,oBAAoB;EACtB;EACA;;IAEE,iBAAiB;EACnB;;EAEA;IACE;MACE,UAAU;MACV,YAAY;MACZ,aAAa;MACb,oBAAoB;IACtB;EACF","sourcesContent":["/*card.css*/\r\n.card-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    width: 100%;\r\n    \r\n  }\r\n  \r\n  .card {\r\n    width: 30%; \r\n    border: 1px solid #ccc;\r\n    padding-inline: 3%;\r\n    padding-bottom: 1%;\r\n    margin: 1%;\r\n    border-radius: 8px;\r\n    background: #ffffff;\r\n  }\r\n  \r\n  h4 {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-bottom: 8px;\r\n    font-weight: bolder;\r\n    color: #c43e00  ;\r\n    \r\n  }\r\n  \r\n  .card p {\r\n    margin: 0;\r\n    font-weight: lighter;\r\n  }\r\n  .card-title-big{\r\n    \r\n    color: #ff5a23   ;\r\n  }\r\n\r\n  @media only screen and (max-width: 768px) {\r\n    .card {\r\n      width: 80%;\r\n      margin: 15px; \r\n      padding: 10px;\r\n      padding-bottom: 20px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
